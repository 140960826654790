<template>
  <BaseSnackbar
    :title="title"
    :text="text"
    :icon="icon"
    icon-size="xx-large"
    :class="`notification-snackbar--${notificationType}`"
    class="notification-snackbar"
    @close="$emit('close')"
  />
</template>

<script>
import BaseSnackbar from '@/components/molecules/BaseSnackbar/Index.vue';

export default {
  name: 'NotificationSnackbar',
  compatConfig: { MODE: 3 },
  components: { BaseSnackbar },
  props: {
    title: String,
    text: String,
    type: {
      type: String,
      validator(type) {
        return [
          'default',
          'error',
          'alert',
          'notice',
          'success',
          'warn',
          'warning',
          'info',
        ].includes(type);
      },
    },
  },
  computed: {
    // Legacy flash used 'alert' and 'notice' so we alias them.
    notificationType() {
      switch (this.type) {
        case 'alert':
          return 'error';
        case 'notice':
          return 'success';
        case 'warning':
          return 'warn';
        default:
          return this.type;
      }
    },
    icon() {
      switch (this.type) {
        case 'error':
        case 'alert':
          return 'b1-close-remove-exit';
        case 'warn':
        case 'warning':
          return 'exclamation-circle';
        case 'success':
        case 'notice':
          return 'check-circle';
        case 'info':
          return 'info';
        default:
          return 'learnamp';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-snackbar--success {
  background-color: $hint-of-green;
  color: $cadmium-green;

  :deep(.snackbar__icon-container) {
    background-color: $north-texas-green;
    color: $white;
  }
  :deep(.base-button__inner) {
    color: $cadmium-green;
  }
}
.notification-snackbar--warn {
  background-color: $lemon-chiffon;
  color: $chocolate;

  :deep(.snackbar__icon-container) {
    background-color: $jonquil;
    color: $chocolate;
  }
  :deep(.base-button__inner) {
    color: $chocolate;
  }
}
.notification-snackbar--error {
  background-color: $pippin;
  color: $rose-wood;

  :deep(.snackbar__icon-container) {
    background-color: $boston-university-red;
    color: $white;
  }
  :deep(.base-button__inner) {
    color: $rose-wood;
  }
}
.notification-snackbar--info {
  background-color: $bubbles;
  color: $oxford-blue;
}
</style>
