<template>
  <span
    class="ic icon"
    :class="{
      [`ic-${iconName}`]: true,
      'ic--success': type === 'success',
      'ic--warning': type === 'warning',
      'ic--danger': type === 'danger',
      'ic--inverted': type === 'inverted',
      'ic--muted': type === 'muted',
      'ic--small': size === 'small',
      'ic--medium': size === 'medium',
      'ic--large': size === 'large',
      'ic--x-large': size === 'x-large',
      'ic--xx-large': size === 'xx-large',
      'ic--bold': transformBold,
      'ic--align-middle': alignMiddle,
    }"
    :style="`color: ${iconColour}`"
    aria-hidden="true"
    focusable="false"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BaseIcon',
  props: {
    iconName: String,
    type: String,
    size: String,
    transformBold: {
      type: Boolean,
      default: false,
    },
    alignMiddle: {
      type: Boolean,
      default: false,
    },
    iconColour: String,
  },
});
</script>

<style scoped lang="scss">
.icon {
  height: 100%;
  position: relative;
  font-size: 12px;
  // Left in case needed in the future
  // color: $gray;
}

.ic--success {
  color: $green;
}

.ic--warning {
  color: $new-orange-light;
}

.ic--danger {
  color: $orange;
}

.ic--muted {
  color: $cool-grey;
}

.ic--small {
  font-size: 80%;
}

.ic--medium {
  font-size: 100%;
}

.ic--large {
  font-size: 120%;
}

.ic--x-large {
  font-size: 140%;
}

.ic--xx-large {
  font-size: 30px;
}

.ic--bold {
  display: inline-block;
  font-weight: bold;
  transform: scaleY(0.6);
}

.ic--inverted {
  color: $white;
}

.ic--align-middle {
  top: 0;
}

// Icon specific overrides

// The cloud icon is poorly balanced compared to others
.ic-cloud-upload {
  top: 1px;
}
</style>
