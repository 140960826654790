<template>
  <BaseSnackbar :title="title" class="banner-snackbar" @close="$emit('close')">
    <template #icon>
      <img :src="starEmojiImage" alt="" />
    </template>
    <template #text>
      <span
        class="banner-snackbar__text"
        data-test-id="banner-snackbar-text"
        v-html="sanitizedText"
      />
    </template>
  </BaseSnackbar>
</template>

<script>
import BaseSnackbar from '@/components/molecules/BaseSnackbar/Index.vue';
import i18n from '@/mixins/i18n';
import starEmojiImage from 'images/star-emoji.svg';
import sanitizeHTML from 'sanitize-html';

export default {
  name: 'BannerSnackbar',
  components: { BaseSnackbar },
  mixins: [i18n],
  props: {
    title: String,
    text: String,
  },
  data: () => ({ starEmojiImage }),
  computed: {
    sanitizedText() {
      return sanitizeHTML(this.text, {
        allowedTags: ['strong'],
        allowedAttributes: {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-snackbar {
  background-color: $hint-of-green;
  color: $north-texas-green;

  .banner-snackbar__text {
    color: $black;
  }

  & ::v-deep .snackbar__title {
    font-weight: 500;
  }

  & ::v-deep .snackbar__icon-container {
    background-color: initial;
    margin-left: 15px;
  }
}
</style>
