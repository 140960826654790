<script lang="ts">
export default {
  name: 'SnackbarNotifications',
};
</script>

<script setup lang="ts">
// Component is rendered in 'application.html.haml'.
import { defineProps, computed, withDefaults } from 'vue';
import { useResponsive } from '@/composables/useResponsive';
import BannerSnackbar from '@/components/molecules/BannerSnackbar/Index.vue';
import NotificationSnackbar from '@/components/molecules/NotificationSnackbar/Index.vue';

interface Props {
  group?: string;
  width?: number;
  position?: string;
}
const props = withDefaults(defineProps<Props>(), {
  width: 420,
  position: 'bottom right',
});

const { screenWidth } = useResponsive();

const snackbarComponent = computed(() => {
  return props.group === 'banner' ? BannerSnackbar : NotificationSnackbar;
});
const notificationWidth = computed(() => {
  const gutter = 15;
  const xOffset = gutter * 2;
  if (screenWidth.value < Number(props.width) + xOffset) {
    return screenWidth.value - xOffset;
  }
  return Number(props.width);
});
</script>

<template>
  <notifications
    :position="position"
    :duration="5000"
    :group="group"
    :width="notificationWidth"
    class="snackbar-notifications m-t-2 m-b-1 m-r-2"
  >
    <template #body="slotProps">
      <component
        :is="snackbarComponent"
        :data-test="slotProps"
        :title="slotProps.item.title"
        :text="slotProps.item.text"
        :type="slotProps.item.type"
        class="m-b-1"
        role="alert"
        @close="slotProps.close"
      />
    </template>
  </notifications>
</template>

<style lang="scss" scoped>
.snackbar-notifications {
  z-index: 10000000;
}
</style>
