<template>
  <div class="snackbar">
    <div class="snackbar__icon-container" data-test-id="snackbar-icon-slot">
      <slot name="icon">
        <BaseIcon
          data-test-id="snackbar-icon"
          class="snackbar__icon"
          :icon-name="icon"
          :size="iconSize"
        />
      </slot>
    </div>
    <div class="snackbar__details-container">
      <div
        v-if="title"
        class="snackbar__title"
        data-test-id="snackbar-title"
        v-html="sanitizeText(title)"
      />
      <div
        v-if="text || ($slots?.text && $slots.text())"
        class="snackbar__text"
        data-test-id="snackbar-text"
      >
        <slot name="text">
          <span v-html="sanitizeText(text)" />
        </slot>
      </div>
    </div>
    <div class="snackbar__button-container">
      <BaseButton
        variant="icon"
        theme="info"
        class="snackbar__close"
        data-test-id="snackbar-close-button"
        prepend-icon="error-cross"
        :title="t('vue_templates.components.base_snackbar.close')"
        :aria-label="t('vue_templates.components.base_snackbar.close')"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import I18n from '@/mixins/i18n';
import sanitize from 'sanitize-html';

export default {
  name: 'BaseSnackbar',
  components: { BaseButton, BaseIcon },
  mixins: [I18n],
  props: {
    title: String,
    text: String,
    icon: String,
    iconSize: {
      type: String,
      default: 'large',
      validator(size) {
        return ['small', 'large', 'x-large', 'xx-large'].includes(size);
      },
    },
  },
  methods: {
    sanitizeText(text) {
      return sanitize(text, {
        allowedTags: ['a'],
        allowedAttributes: { a: ['href'] },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.snackbar {
  display: flex;
  background-color: $white-smoke;
  color: $charcoal;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);

  .snackbar__icon {
    // Override funky styles
    height: auto;
    top: auto;
  }
}
.snackbar__icon-container {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}
.snackbar__details-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 15px;
  flex-grow: 1;
}
.snackbar__button-container {
  padding: 10px;
}
.snackbar__close {
  position: static;

  &:hover {
    color: inherit;
  }

  & ::v-deep .ic {
    line-height: inherit;
  }
}
.snackbar__title {
  flex-basis: 100%;
  font-size: 16px;
  font-weight: 500;
}
.snackbar__text {
  flex-basis: 100%;
}
</style>
