import _, { DebouncedFunc } from 'lodash';
import { onMounted, ref, Ref, onBeforeUnmount, computed } from 'vue';

export interface IBreakpoint {
  min: number;
  max: number | null;
}
export interface IBreakpoints {
  xxs: IBreakpoint;
  xs: IBreakpoint;
  sm: IBreakpoint;
  md: IBreakpoint;
  lg: IBreakpoint;
}

const xxs = 0;
const xs = 425;
const sm = 768;
const md = 992;
const lg = 1200;

export const getDefaultBreakpoints = (): IBreakpoints => ({
  xxs: {
    min: xxs,
    max: xs - 1,
  },
  xs: {
    min: xs,
    max: sm - 1,
  },
  sm: {
    min: sm,
    max: md - 1,
  },
  md: {
    min: md,
    max: lg - 1,
  },
  lg: {
    min: lg,
    max: null,
  },
});

export function useResponsive(breakpoints: IBreakpoints = getDefaultBreakpoints()) {
  const debouncedGetScreenWidth: Ref<DebouncedFunc<() => void> | null> = ref(null);
  const screenWidth: Ref<number> = ref(0);

  const isTouchOnly = () => {
    return !!window.matchMedia('(any-hover: none)').matches;
  };

  const getscreenWidth = () => {
    screenWidth.value = document?.body?.clientWidth;
  };

  const breakpoint = computed(() => {
    const bp = Object.entries(breakpoints).find(([, { min, max }]) => {
      if (screenWidth.value < min) {
        return false;
      }
      if (max && screenWidth.value > max) {
        return false;
      }
      return true;
    });
    return (bp && bp[0]) || 'xxs';
  });

  const smAndUp = computed(() => {
    return ['sm', 'md', 'lg'].includes(breakpoint.value);
  });
  const mdAndUp = computed(() => {
    return ['md', 'lg'].includes(breakpoint.value);
  });
  const xsOnly = computed(() => {
    return breakpoint.value === 'xs';
  });
  const smOnly = computed(() => {
    return breakpoint.value === 'sm';
  });
  const mdOnly = computed(() => {
    return breakpoint.value === 'md';
  });
  const lgOnly = computed(() => {
    return breakpoint.value === 'lg';
  });

  onMounted(() => {
    getscreenWidth();
    debouncedGetScreenWidth.value = _.debounce(getscreenWidth, 50);
    window.addEventListener('resize', debouncedGetScreenWidth.value);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('resize', getscreenWidth);
  });

  return {
    screenWidth,
    breakpoint,
    smAndUp,
    mdAndUp,
    xsOnly,
    smOnly,
    mdOnly,
    lgOnly,
    isTouchOnly,
  };
}
